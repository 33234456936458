import React from 'react'
import { images } from 'config/images'
export const CrayPayProps = {
  bannerProps: {
    heading: 'CrayPay',
    bannerPara:
      'uses mobile technology to ease the adoption of M-Payment in retail stores.',
    BannerAlt: 'CrayPay Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        81% of the US population owns a smartphone, despite the heavy dependence
        on the device, American's are still not using smartphones for payments
        in retail stores. Hence, with savings on every transaction, CrayPay is
        aiming to make mobile a standard mode of payment in retail stores.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainSmallTitle:
      'Modernising retail shopping by reducing checkout time and improving customer experience',
    CasePowerToolsDetails: [
      {
        para: (
          <>
            Some of the common challenges of contact payments were long checkout
            wait time, the possibility of fraud, lengthy bookkeeping, and poor
            customer experience. CrayPay is eliminating all these challenges by
            establishing a global network of local merchants who accept mobile
            payment without specialized hardware or software integration. <br />{' '}
            <br />
            Over 70% of consumers say that if they get benefits of loyalty
            points or perks in retail stores, they are ready to switch to mobile
            payment. Therefore, by paying up to 10% of the customers billing
            amount at checkout, CrayPay is driving consumers to local stores
            with incentives that don't negatively affect the merchant's bottom
            line.
          </>
        ),
      },
    ],
  },
  CasemobileappsProps: {
    Title: 'First Version',
    subTitle: <>Disappointing First version of the Mobile App</>,
    para: (
      <>
        Before Simform, CrayPay hired a tech team consisting of tech Leads,
        frontend & backend engineers, and designers. Lack of collaboration
        during the development process and improper analysis of requirement
        understanding lead to a substandard application. The app fared poorly on
        many fronts, such as unappealing user features, flat UI, and lengthy
        checkout processes.
      </>
    ),
    caseQuotewithThumbProps: {
      maintext: (
        <>
          <span>“Simform have gone above and beyond our expectations,</span>{' '}
          integrating seamlessly with our own team to deliver cutting edge
          technology in a timely and professional manner.”
        </>
      ),
    },
  },
  CaseengagementsecProps: {
    title: 'CrayPay meets common ground with Simform',
    bottomTitle: 'Engagement',
    bottomContent: (
      <>
        Moved from <br />
        Native to Flutter
      </>
    ),
    maincontent: (
      <>
        Due to the poor execution of the first app, the founders of CrayPay were
        looking for a tech team that can be collaborative at every stage of the
        development process.{' '}
        <strong>
          Our extended team offering turned out to be an ideal fit for them.
        </strong>
        <br /> <br />
        To close the gap between the founder's vision and our understanding of
        the product. We followed the agile methodology, an iterative approach to
        software delivery, which allowed the founders to be a part of the
        development process. <br /> <br />
        Since we wanted to develop a cost and time-effective solution,{' '}
        <strong>
          our team worked with CrayPay's COO to move the development efforts
          from native platforms to flutter.
        </strong>{' '}
        We spent the first two weeks adopting agile processes, code reviews, and
        refactoring best practices. To integrate the team, we leveraged sprint
        planning, daily standups, weekly discussions, and monthly
        retrospectives.
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “I've used Simform in the past for iOS and currently for native
            Android development.
          </span>{' '}
          We already had a back end team for services and a UI/UX team in place.
          I've found their developers to be knowledgeable and helpful. They
          adapted to our project flow that uses Zeplin and Jira without issue.”
        </>
      ),
    },
  },
  CaseresultwithvideoProps: {
    fadeInsecProps: {
      animationImageBlock: [
        {
          imgUrl2x: images.craypayShippingAmountTicket2x,
          imgUrl: images.craypayShippingAmountTicket,
        },
        {
          imgUrl2x: images.craypayFilterSaving2x,
          imgUrl: images.craypayFilterSaving,
        },
        {
          imgUrl2x: images.craypayPurchaseTicket2x,
          imgUrl: images.craypayPurchaseTicket,
        },
      ],
    },
    heading: 'Result',
    para: (
      <>
        Matching the experience of current credit card wait times, CrayPay is a
        massive success in processing mobile transactions in mere seconds.
        Impressive features, smooth UI, and money-saving perks made CrayPay an
        app for smart users.
        <br />
        <br />
        <strong>
          As a result, it has 10,000+ active users purchasing at 60,000 retail
          locations across the United States.
        </strong>
      </>
    ),
    resultvideoURL: images.craypayLogoVideo,
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Management has a desire to see that they take care of the client. I
            appreciate that.
          </span>{' '}
          For front end work I would recommend them for early stage startups.
          They'll work hard to keep you happy.”
        </>
      ),
    },
  },
  Fasterdevelopmentprops: {
    heading:
      'Leveraging Cross-Platform Technology to reduce development efforts by 60%',
    maintitle:
      'Flutter allowed our development team to maintain a single code base for both Android and IOS.',
    mainpara: (
      <>
        When developing an app like CrayPay, we had to make sure that we picked
        a framework that was simple to set-up and allowed easy and smooth fixes.
        Since CrayPay had to work on multiple platforms, it was ideal to select
        a framework that could readily deploy custom user interfaces for Android
        and iOS.
        <br />
        <br />
        <strong>
          Hence we decided to switch from native platforms to Flutter - an
          open-source, cross-platform mobile app development framework.
        </strong>{' '}
        Flutter helped us in unburdening the developers from rewriting
        everything from scratch in case we wanted changes in UI. The tech switch
        scored us our first win by reducing the development efforts by 60% and
        achieving significant cost reduction for the client.
      </>
    ),
  },
  buildingAppProps: {
    heading: 'Building a high performing and scalable application',
    maintitle:
      'MVVM made it easy to maintain, test and improve the code whenever necessary. Without disturbing the UI and vice versa. ',
    mainpara: (
      <>
        CrayPay’s initial app was built without an architecture, making it a
        daydream for the founders to maintain the app when the code base grows,
        and the team expands. Hence, we implemented MVVM (Model - View -
        ViewModel) - an architectural framework that allowed us to separate the
        development of the UI from the backend. CrayPay has to incorporate
        frequent changes and keep pushing out new releases.
        <br />
        <br />
        Further, we used Dagger, a dependency injection framework that allowed
        the developers to write code that has low coupling and can be easily
        tested and maintained. We also used crashlytics for crash reporting,
        application logging, online review, and statistical analysis of
        application logs.
      </>
    ),
  },
  advancedFeaturesProps: {
    mainTitle: 'Advanced analytics and user engagement features',
    CasePowerToolsDetails: [
      {
        para: (
          <>
            The challenge in designing a dashboard for CrayPay users is
            providing discounts and location-based information. We made
            data-rich dashboards that pull and display merchant information from
            close-proximity (10 miles). The users can filter clients according
            to the merchant category and view coupon history.
            <br />
            <br />
            We optimized the customer shopping experience by pairing customer
            preference data in real-time with geo-marketing. We implemented
            location tracking and geocoding in Flutter using two plugins -
            ‘location’ and ‘locator.’ The application provided information about
            the location of transactions, which helped in early fraud detection
            and charge-back resolutions.
          </>
        ),
      },
    ],
  },
  additionalFeatureProps: {
    mainTitle: 'Additional features for unparalleled user experience',
    para:
      'CrayPay provides instant cash savings on daily purchases at over 150,000 locations. The app utilizes the stored value of gift cards to offer immediate savings on all purchases made by users. Our features delivered advanced solutions for collecting consumer data and influencing purchasing behavior.',
    additionalFeatureDetails: [
      {
        rowContentData: {
          SubTitle: 'Wallet Features',
          para: (
            <>
              Every CrayPay transaction earns users CrayCoins as cashback.
              CrayCoins are real money tokens to be used while shopping. Not
              only that, CrayPay pays 10% of its users' bill, making customers
              save money in every transaction.
            </>
          ),
        },
      },
      {
        rowContentData: {
          SubTitle: 'Faster Checkouts',
          para:
            'To reduce the abandonment rate, we reduced the checkout stage from 5 steps (initial app) to 2 simple easy-to-follow steps.',
        },
      },
      {
        rowContentData: {
          SubTitle: 'Money Savers',
          para:
            'CrayPay’s flexible and scalable architecture allows the company to affiliate with all possible local merchants easily, which helps them provide exclusive gift cards and loyalty coupons to users.',
        },
      },
      {
        rowContentData: {
          SubTitle: 'Checkout Discounts',
          para:
            'Customers can shop online or offline using gift cards, coupons, retail discounts, etc. Also, during checkout, CrayPay pays up to 10% of their bill amount.',
        },
      },
    ],
  },
  bookRideContent: {
    maintile:
      'Advance Feature Making Cryptocurrency spendable in retail stores',
    paraLeft:
      'With collective value crossing 0.5 trillion USD, cryptocurrency is still not spendable/acceptable by merchants in retail stores. To build a highly profitable platform, we decided to make this currency spendable.',
    paraRight: (
      <>
        By eliminating two main hurdles of Cryptocurrency transactions - slow
        transaction speed and fluctuations in value relative to the dollar. We
        made unusable cryptocurrency spendable using CrayCoins. Which allows
        users to use their cryptocurrency to make purchases in-store and online
        across 120+ national brand partners including AMC Theatres, American
        Eagle, Best Buy, Chili’s, Domino’s, GAP etc.
      </>
    ),
    fadeInsecProps: {
      animationImageBlock: [
        {
          imgUrl2x: images.craypayRetailStoresImg12x,
          imgUrl: images.craypayRetailStoresImg1,
        },
        {
          imgUrl2x: images.craypayRetailStoresImg22x,
          imgUrl: images.craypayRetailStoresImg2,
        },
        {
          imgUrl2x: images.craypayRetailStoresImg32x,
          imgUrl: images.craypayRetailStoresImg3,
        },
      ],
    },
  },
  ReliabilityinstancesProps: {
    trackheading:
      'Using Firebase A/B Testing for User Engagement and High Retention',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'A consumer app that incentivizes its user to make purchases, it’s indispensable for the tech to support customized messaging points to bring back visitors who drop out at various stages of purchases. Therefore, for CrayPay, we configured Firebase A/B testing to re-engage users by using the notification composer. The test features allowed us to leverage user behavior to offer dynamic discounts to dropout users.',
      },
    ],
  },
  constraintExpSecProps: {
    maintile: 'Constraint Layout for seamless UI experience',
    paraLeft:
      'Micro screen or a full television screen, devices today come in all shapes and sizes. Hence, we had to be critical that our UI responds seamlessly across devices with unique orientations.',
    paraRight: (
      <>
        Hence, we created a responsive layout for different screens using
        Constraint Layout - a layout editor used to create flexible designs.
        Using Constraint Layout as the base layout in our UI, we were able to
        specify the position and size of each view, making screens act as per
        the resolution of each device.
      </>
    ),
  },
  twocolcontentProps: {
    heading: 'Building a Secure Mobile Payment Application',
    maintitle:
      'We implemented point-to-point encryption, tokenization and passwords protection as the basic features in the first version.',
    mainpara: (
      <>
        Since the application had to launch in over 50,000 retail locations,
        encryption at the PoS terminal was the first step towards security.
        Through tokenization, we encrypted user card information and turned it
        into unidentifiable numbers.
      </>
    ),
    mainImgcaption: 'Point-to-Point encryption for high security',
    subheading: 'Tech Architecture',
    subheadingtitle:
      'Breaking traditional architecture into small micro-services which are independent',
    subpara:
      'In the second phase of development, we added a few more security features, such as storing the hardware device information to avoid multiple signups.',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'SentMap',
        para: 'Sentiment based market analytics engine for portfolio managers',
        className: 'pale-blue',
        link: 'case-studies/sentmap/',
      },
      {
        title: 'Food Truck Spaces',
        para:
          'Eases finding, booking and scheduling properties in the $2 Billion Food Industry',
        className: 'light-peach',
        link: 'case-studies/food-truck-spaces/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
