import React, { Component } from 'react'
import { LocarridebooksecStyles } from './Locarridebooksec.styles'
import Scrollfadeanimationsec from 'components/Scrollfadeanimationsec/Scrollfadeanimationsec'

class Locarridebooksec extends Component {
  render() {
    const {
      bookRideData: { bookRideContent },
    } = this.props
    const fadeInsecProp = bookRideContent.fadeInsecProps
    return (
      <LocarridebooksecStyles className="locarridebooksec">
        <div className="container">
          <h2>{bookRideContent.maintile}</h2>
          <p>{bookRideContent.mainpara}</p>
          {bookRideContent.subheading ||
          bookRideContent.paraLeft ||
          bookRideContent.paraRight ? (
            <div className="sub-content">
              <h3>{bookRideContent.subheading}</h3>
              <div className="row">
                <div className="col">
                  <p>{bookRideContent.paraLeft}</p>
                </div>
                <div className="col">
                  <p>{bookRideContent.paraRight}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <Scrollfadeanimationsec {...fadeInsecProp} />
        </div>
      </LocarridebooksecStyles>
    )
  }
}

export default Locarridebooksec
