import React from 'react'
import { CaseresultwithvideosecStyles } from './Caseresultwithvideosec.styles'
import Scrollfadeanimationsec from 'components/Scrollfadeanimationsec/Scrollfadeanimationsec'
import Mobileappscreensec from 'components/Mobileappscreensec/Mobileappscreensec'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Image from 'components/ImgOptimized'

function Caseresultwithvideosec(props) {
  const {
    CaseresultwithvideoData: { CaseresultwithvideoProps },
  } = props
  const fadeInsecProp = CaseresultwithvideoProps.fadeInsecProps
  const caseQuoteProp = CaseresultwithvideoProps.caseQuoteProps
  return (
    <CaseresultwithvideosecStyles className="caseResultwithvideosec">
      <div className="container">
        <Scrollfadeanimationsec {...fadeInsecProp} />
        <div className="resultwithvideoContent">
          <div className="heading">
            <h2>{CaseresultwithvideoProps.heading}</h2>
          </div>
          <div className="resultwithvideoRow">
            <div className="resultContent">
              <p>{CaseresultwithvideoProps.para}</p>
            </div>
            <div className="resultVideoContent">
              {CaseresultwithvideoProps.resultvideoURL ? (
                <video autoPlay muted loop playsInline>
                  <source
                    src={CaseresultwithvideoProps.resultvideoURL}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <Image
                  fluid={props.resultImg}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 511,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="resultScreenListSec">
        <div className="container">
          <Mobileappscreensec screenList={props.appScreenList} {...props} />
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </CaseresultwithvideosecStyles>
  )
}

export default Caseresultwithvideosec
