import styled from 'styled-components'
import { metaFont, bodyFont, md, lg, xl } from 'config/variables'

export const TwocolcontentStyles = styled.div`
  padding-bottom: 50px;
  margin-bottom: 120px;
  position: relative;
  ${xl(`
      margin-bottom: 100px;
  `)}
  ${lg(`
      padding-bottom: 40px;
      margin-bottom: 90px;
  `)}
  ${md(`
      padding-bottom: 20px;
      margin-bottom: 40px;
  `)}
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 646px;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    z-index: -1;
    bottom: 0;
    left: 0;
  }
  .row {
    display: flex;
    justify-content: space-between;
    ${md(`
        flex-direction: column;
    `)}
    .col-head {
      max-width: 240px;
      width: 100%;
      ${lg(`
          max-width: 132px;
      `)}
      ${md(`
        max-width: 100%;
    `)}
    &.bottom-col-head {
      ${md(`
        display:none;
    `)}
    }
      h3 {
        font-size: 30px;
        line-height: 50px;
        font-family: ${metaFont};
        ${lg(`
          font-size: 22px;
          line-height: 35px;
      `)}
      }
      .border-heading {
        position: relative;
        padding-top: 21px;
        margin-top: -20px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 55px;
          height: 3px;
          background: #3d3d3d;
        }
        h4 {
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.16px;
          margin-bottom: 6px;
          font-weight: 600;
          font-family: ${bodyFont};
          text-transform: uppercase;
        }
        p {
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.16px;
        }
      }
    }
    .col-content {
      max-width: calc(100% - 272px);
      width: 100%;
      ${lg(`
          max-width: calc(100% - 132px);
          padding-left:20px;
      `)}
      ${md(`
        max-width: 100%;
        padding-left:00px;
    `)}
    }
  }
`
