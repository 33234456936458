import styled from 'styled-components'
import { sm, md, xl, lg } from 'config/variables'
import { images } from 'config/images'

export const MobileappscreensecStyles = styled.div`
margin-bottom:80px;
${xl(`
    margin-bottom:70px;
`)} 
${lg(`
    margin-bottom:50px;
`)} 
${md(`
    margin-bottom:30px;
`)} 
.AppScreenMain {
    background:url('${images.ihealthAppSecBg}') no-repeat;
    background-size: 100% 98%;
    background-position:center;
    padding:10px 70px;
    overflow:auto;
    ${xl(`
         padding:10px 80px;
    `)} 
    ${lg(`
        padding:10px 45px;
    `)} 
    ${md(`
        padding:10px 15px;
    `)} 
    .AppScreenRow {
        width:100%;
        display:flex;
        align-items:center;
        ${md(`
            width: 507px;
            min-width: 100%;
        `)} 
        .AppScreenItem {
            flex:0 1 33.33%;
            text-align:center;
            padding:0 15px;
            .appScreenMockup {
                padding-bottom:80px;
                ${xl(`
                    padding-bottom:70px;
                `)} 
                ${lg(`
                    padding-bottom:50px;
                `)} 
                ${md(`
                    padding-bottom:20px;
                `)} 
                &:last-child {
                    padding-bottom:0px;
                }
            }
        }
    }
}
`
