import React from 'react'

import { MobileappscreensecStyles } from './Mobileappscreensec.styles'
import Image from 'components/ImgOptimized'

function Mobileappscreensec(props) {
  return (
    <MobileappscreensecStyles className="Mobileappscreensec">
      <div className="AppScreenMain">
        <div className="AppScreenRow">
          {props.screenList.map((screenImgItems, i) => {
            return (
              <div key={i} className="AppScreenItem">
                {screenImgItems.map((screenImgItems, i) => {
                  return (
                    <div key={i} className="appScreenMockup">
                      <Image
                        fluid={screenImgItems}
                        lazyload={{
                          enabled: true,
                          once: true,
                          offset: 1000,
                          heigth: 492,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </MobileappscreensecStyles>
  )
}

export default Mobileappscreensec
