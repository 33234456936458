import styled from 'styled-components'
import { bodyFont, lg, md, xl, sm } from 'config/variables'
import { images } from 'config/images'

export const ConstraintexperiencesecStyles = styled.div`
  padding: 82px 0 0;
  background: url(${
    images.craypayConstraintSeamlessBg
  }) no-repeat center center;
  background-size: cover;
  margin-bottom: 254px;
  ${xl(`   
    margin-bottom: 220px; 
  `)}
  ${lg(`
     margin-bottom: 168px; 
     padding: 60px 0 0;
  `)}
  ${md(`
    padding: 40px 0 0;
    margin-bottom: 100px; 
  `)}
  ${sm(` 
    margin-bottom: 80px; 
  `)}
  h2 {
    color: #fff;
  }
  .sub-content {
    margin-bottom: -76px;
    ${lg(`
      margin-bottom: -46px;
    `)}
    ${md(`
       margin-bottom: -40px;
    `)}
    ${sm(`
       margin-bottom: -20px;
    `)}
    .row {
      display: flex;
      justify-content: space-between;
      ${lg(`
        flex-direction: column;
      `)}
      .col {
        width: 50%;
        p {
          color: #fff;
        }
        ${lg(`
          width: 100%;
        `)}
        &:last-child {
          p {
            ${md(`
              margin-bottom:0px;
            `)}
          }
        }
      }
    }
  }
  .constraintExpImg {
    max-width: 646px;
    margin: 0 auto;
    position: relative;
    top: 152px;
    ${lg(`
      top: 100px;
    `)}
    ${md(`
      top: 80px;
    `)}
    ${sm(`
      top: 40px;
    `)}
  }
`
