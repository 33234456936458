import styled from 'styled-components'
import { bodyFont, lg, md, xl } from 'config/variables'
export const LocarridebooksecStyles = styled.div`
  margin-bottom: 120px; 
  overflow:hidden;
    width:100%;
  ${xl(`   
      margin-bottom: 100px;
      padding:62px 0 0;
  `)}
  ${lg(`
  margin-bottom: 100px;
      padding:50px 0 0;
  `)}
  ${md(`
  margin-bottom: 40px;
      padding:20px 0 0;
  `)}
  p {
    margin-bottom: 43px;
  }
  .sub-content {
    h3 {
      font-family: ${bodyFont};
      font-weight: 500;
      margin-bottom: 23px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      ${md(`
        flex-direction: column;
      `)}
      .col {
        width: 47.5%;
        ${md(`
        width: 100%;
      `)}
        &:last-child {
          p {
            ${md(`
        margin-bottom:0px;
      `)}
          }
        }
      }
    }
  }
`
