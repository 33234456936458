import React from 'react'
import { ScrollfadeanimationsecStyles } from './Scrollfadeanimationsec.styles'
import Picture from 'components/Picture/Picture'

function Scrollfadeanimationsec(props) {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef()
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(domRef.current)
  }, [])
  return (
    <ScrollfadeanimationsecStyles className="scrollFadeAnimationSec">
      <div
        className={`bookRideImgRow ${isVisible ? 'isShow' : 'isHide'}`}
        ref={domRef}
      >
        {props.animationImageBlock.map((imageData, i) => {
          return (
            <div key={i} className="bookRideImg">
              <Picture
                height={70}
                offset={2000}
                srcSet={imageData.imgUrl2x}
                src={imageData.imgUrl}
                alt="Filipa"
              />
            </div>
          )
        })}
      </div>
    </ScrollfadeanimationsecStyles>
  )
}

export default Scrollfadeanimationsec
