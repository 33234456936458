import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md, xl, sh, sm } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { images } from 'config/images'
import { CrayPayProps } from 'components/Props/cray-pay'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Casemobileappssec from 'components/Casemobileappssec/Casemobileappssec'
import Caseengagementsec from 'components/Caseengagementsec/Caseengagementsec'
import Caseresultwithvideosec from 'components/Caseresultwithvideosec/Caseresultwithvideosec'
import Fasterdevelopmentsec from 'components/Fasterdevelopmentsec/Fasterdevelopmentsec'
import Additionalappfeaturesec from 'components/Additionalappfeaturesec/Additionalappfeaturesec'
import Locarridebooksec from 'components/Locarridebooksec/Locarridebooksec'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Constraintexperiencesec from 'components/Constraintexperiencesec/Constraintexperiencesec'
import Twocolcontent from 'components/Twocolcontent/Twocolcontent'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const CrayPayPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        max-width: 604px;
        .banner-img {
          margin: 0px;
          width: 100%;
          .banner-bg {
            display: none;
          }
        }
      }
    }
  }
  .caseintro-section {
    padding-bottom: 40px;
    position: relative;
    &::after {
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
      position: absolute;
      width: 100%;
      height: 633px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .businessFullImage {
      figure {
        margin-bottom: 0px;
      }
    }
  }
  .casepower-section  {
    margin-bottom: 120px; 
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)}  
  }
  .caseMobileAppsSec {
    .mobileAppsContent {
      padding-bottom:0px;
    }  
  }
  .caseEngagementSec { 
    .casequote-section {
      &::after {
        background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
      }
      .container { 
        .quote-content {
          .iconquote { 
            background: url(${images.yellowLgQuote});
            background-size: cover;
          }
          .quote-content-wrapper {
            .quote {
              span {
                color:#1a49ba;
              }
            }
          }  
        }  
      }
    }
  }
  .caseResultwithvideosec { 
    .casequote-section {
      .container {
        max-width: 1220px;
        margin: 0 auto;
        padding: 0 40px;
        ${sh(`
          max-width: 1100px;
          padding: 0 36px;
        `)}
        ${md(`
          padding: 0 30px;
          `)}
        ${sm(`
          padding: 0 20px;
        `)}
      }
      &::after {
        background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
      }
      .container { 
        .quote-content {
          .iconquote { 
            background: url(${images.yellowLgQuote});
            background-size: cover;
          }
          .quote-content-wrapper {
            .quote {
              span {
                color:#1a49ba;
              }
            }
          }  
        }  
      }
    }
  }
  .fasterdevelopmentsec{
    margin-bottom: 120px; 
    ${xl(`   
      margin-bottom: 100px; 
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
      margin-bottom: 40px; 
    `)}
    .FasterDevservicContent {
      margin-bottom:0px
    }
  }
  .advancedFeaturesSec {
    .casepower-section {
      padding:70px 0;
      background:#0070ba;
      ${lg(`
        padding:50px 0;
      `)}
      ${md(`
        padding:40px 0; 
      `)}
      .CasePowerToolsMainTitle {
        h2 {
          color:#fff;
          margin-bottom:20px;
        }
      }
      .powerContentRow {
        .powerToolsDetails {
          color:#fff;
        }
      }
    }
  }
  .additionalAppFeaturesec { 
    .mobielAppScreenDetails {
      max-width:934px;
      margin:0 auto;
      padding-bottom:40px;
      ${lg(`
          padding-bottom:0;
      `)}
      .secRow { 
        justify-content: space-between; 
        margin-bottom:0; 
        ${lg(` 
          padding-bottom:40px; 
        `)}
        ${md(` 
            padding-bottom:30px; 
        `)}
        .secContentCol { 
          padding-right:130px; 
          flex: 1 1 auto;
          ${lg(`
            padding:0 0 40px; 
            flex:1 1 auto;
          `)}
          ${md(`
            padding:0 0 30px;  
          `)}
        }
        .secImgCol {
          margin:-40px 0;
          padding:0px 0 0 0; 
          flex: 0 0 300px; 
          ${lg(`
            padding:0; 
            margin:0px;
            flex:1 1 auto;
          `)}
          ${md(`
           padding:0; 
            flex:1 1 auto;
          `)}
        }
        &:nth-child(even) {
          .secContentCol {
            padding:0px 0 0 20px; 
            padding-left:130px; 
            ${lg(`
              padding: 0;
              order: 1;
              padding-bottom: 50px;
            `)}
            ${md(`
              flex-direction: column;
              padding-bottom: 30px;
            `)}
          }
          .secImgCol {
            padding: 0px;
            ${lg(`
              padding:0; 
              flex:1 1 auto;
            `)}
            ${md(`
              padding:0; 
              flex:1 1 auto;
            `)}
          }
        } 
      }
    }
  }
  .locarridebooksec {
    padding:0px;
    .scrollFadeAnimationSec  {
      .bookRideImgRow {
        padding-bottom:43px;
        ${md(`
            margin:30px -5px 0;
            padding-bottom:0;    
          `)}
        ${sm(`
            margin-top:30px;
            flex-direction: column;
          `)}
        .bookRideImg {
          width: calc(33.33% + 85px);
          flex-basis: calc(33.33% + 85px);
          ${md(`
            width: 33.33%;
            flex-basis: 33.33%;
            flex-grow: 0;
            flex-shrink: 0;
            padding:5px;
          `)}
          ${sm(`
            width: 100%;
          `)}
          &:nth-child(2) {
            top:70px;
          }
        } 
      }
    }
  }
  .reliabilityinstancessec {
    .track-content {
      .row {
        .col {
          &.colImages {
            flex: 0 1 57%;
          }
          &.colDetails {
            flex: 0 1 43%;
          }
        }
      }
    }
  }
  .twocolcontentSec {
    padding-bottom:0px; 
    &::after {
      display:none;
    }
  }
`

const CrayPay = memo(props => {
  const { data } = props
  const { location } = props

  let item = [props.data.crayPayCusExpeImg.childImageSharp.fluid]
  let quoteThumb = [props.data.quoteThumb.childImageSharp.fluid]

  let screenListItem = [
    [
      props.data.craypayAppScreen1.childImageSharp.fluid,
      props.data.craypayAppScreen2.childImageSharp.fluid,
      props.data.craypayAppScreen3.childImageSharp.fluid,
    ],
    [
      props.data.craypayAppScreen4.childImageSharp.fluid,
      props.data.craypayAppScreen5.childImageSharp.fluid,
    ],
  ]
  const fullRowImage = props.data.fullRowImage.childImageSharp.fluid
  const buildAppfullRowImage =
    props.data.buildAppfullRowImage.childImageSharp.fluid
  let featuresItem = [props.data.crayPayfeaturesScreen.childImageSharp.fluid]

  let featureImg = [
    props.data.walletImg.childImageSharp.fluid,
    props.data.checkoutsScreeen.childImageSharp.fluid,
    props.data.moneySaveScreen.childImageSharp.fluid,
    props.data.discountScreen.childImageSharp.fluid,
  ]
  let crowdTest = [props.data.mixpanel.childImageSharp.fluid]
  let ExpImg = [props.data.experienceImg.childImageSharp.fluid]
  const topDaiImg = props.data.microservicesArchitecture.childImageSharp.fluid
  const resultLogoImg = props.data.resultLogoImg.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="CrayPay"
        description=""
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/cray-pay/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/cray-pay/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <CrayPayPage>
        <Casebanner
          bannerdata={CrayPayProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={CrayPayProps}
          {...props}
          showFullimages={true}
        />
        <div className="lazy-load-div">
          <CasePowerTools
            CasePowerToolsdata={CrayPayProps.CasePowerToolsProps}
            items={item}
          />
        </div>
        {showContent && (
          <>
            <Casemobileappssec
              Casemobileappsdata={CrayPayProps}
              quoteThumb={quoteThumb}
              {...props}
            />
            <Caseengagementsec
              Caseengagementsecdata={CrayPayProps.CaseengagementsecProps}
            />
            <Caseresultwithvideosec
              CaseresultwithvideoData={CrayPayProps}
              appScreenList={screenListItem}
              resultImg={resultLogoImg}
            />
            <Fasterdevelopmentsec
              FasterdevelopmentData={CrayPayProps.Fasterdevelopmentprops}
              {...props}
              fullRowImage={fullRowImage}
            />
            <Fasterdevelopmentsec
              FasterdevelopmentData={CrayPayProps.buildingAppProps}
              {...props}
              fullRowImage={buildAppfullRowImage}
            />
            <div className="advancedFeaturesSec">
              <CasePowerTools
                CasePowerToolsdata={CrayPayProps.advancedFeaturesProps}
                items={featuresItem}
              />
            </div>
            <Additionalappfeaturesec
              additionalFeatureData={CrayPayProps}
              imgData={featureImg}
            />
            <Locarridebooksec bookRideData={CrayPayProps} />
            <Reliabilityinstancessec
              ReliabilityinstancesData={CrayPayProps.ReliabilityinstancesProps}
              {...props}
              fluid={crowdTest}
            />
            <Constraintexperiencesec
              constraintExpSecData={CrayPayProps}
              ImgData={ExpImg}
            />
            <Twocolcontent
              twocolcontentdata={CrayPayProps}
              colTopDaigram={topDaiImg}
              {...props}
            />
            <Relatedcaselist relatedcaselistdata={CrayPayProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={CrayPayProps} />
          </>
        )}
      </CrayPayPage>
    </Layout>
  )
})
export default CrayPay

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/cray-pay-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 604) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/craypay-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1095) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    crayPayCusExpeImg: file(
      relativePath: { regex: "/cray-pay-cus-expe-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 520) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    quoteThumb: file(
      relativePath: { regex: "/craypay-testimonial-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 478) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    craypayAppScreen1: file(
      relativePath: { regex: "/craypay-app-screen1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 313) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    craypayAppScreen2: file(
      relativePath: { regex: "/craypay-app-screen2@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 313) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    craypayAppScreen3: file(
      relativePath: { regex: "/craypay-app-screen3@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 313) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    craypayAppScreen4: file(
      relativePath: { regex: "/craypay-app-screen4@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 313) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    craypayAppScreen5: file(
      relativePath: { regex: "/craypay-app-screen5@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 313) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    resultLogoImg: file(
      relativePath: { regex: "/craypay-result-logo-group-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 369) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fullRowImage: file(
      relativePath: { regex: "/craypay-crossplatform-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 808) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    buildAppfullRowImage: file(
      relativePath: { regex: "/craypay-build-app-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 796) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    crayPayfeaturesScreen: file(
      relativePath: { regex: "/craypay-features-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 508) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    walletImg: file(
      relativePath: { regex: "/craypay-wallet-feature@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 304) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    checkoutsScreeen: file(
      relativePath: { regex: "/craypay-checkouts-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 304) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    moneySaveScreen: file(
      relativePath: { regex: "/craypay-money-save-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 304) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    discountScreen: file(
      relativePath: { regex: "/craypay-discount-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 304) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(
      relativePath: { regex: "/craypay-notification-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    experienceImg: file(
      relativePath: { regex: "/craypay-experience-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 646) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    microservicesArchitecture: file(
      relativePath: { regex: "/microservices-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/sentmap-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/fts-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
