import React from 'react'
import { ConstraintexperiencesecStyles } from './Constraintexperiencesec.styles'
import Image from 'components/ImgOptimized'

function Constraintexperiencesec(props) {
  const {
    constraintExpSecData: { constraintExpSecProps },
  } = props
  return (
    <ConstraintexperiencesecStyles>
      <div className="container">
        <h2>{constraintExpSecProps.maintile}</h2>
        <div className="sub-content">
          <h3>{constraintExpSecProps.subheading}</h3>
          <div className="row">
            <div className="col">
              <p>{constraintExpSecProps.paraLeft}</p>
            </div>
            <div className="col">
              <p>{constraintExpSecProps.paraRight}</p>
            </div>
          </div>
        </div>
        <div className="constraintExpImg">
          <Image
            fluid={props.ImgData}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 411,
            }}
          />
        </div>
      </div>
    </ConstraintexperiencesecStyles>
  )
}

export default Constraintexperiencesec
