import React from 'react'

import { CasequotewiththumbsecStyles } from './Casequotewiththumbsec.styles'
import Picture from 'components/Picture/Picture'
import Image from 'components/ImgOptimized'

function Casequotewiththumbsec(props) {
  return (
    <CasequotewiththumbsecStyles className="Casequotewiththumbsec">
      <div className="container">
        <div className="quoteContentRow">
          {props.quoteThumb ? (
            <div className="quoteThumb">
              <Image
                fluid={props.quoteThumb}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 680,
                }}
              />
            </div>
          ) : (
            ''
          )}
          <div className="quoteContentCol">
            <div className="quote-content">
              <div className="iconquoteRow">
                <div className="iconquote"></div>
                <hr />
              </div>
              <div className="quote-content-wrapper">
                <div className="quote">{props.maintext}</div>
                {props.userName && (
                  <div className="user-info">
                    {props.userImg && (
                      <div className="user-img">
                        <Picture
                          height={50}
                          offset={2000}
                          srcSet={props.userImg2x}
                          src={props.userImg}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="username">
                      {props.userName}
                      <span className="designation">{props.designation}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasequotewiththumbsecStyles>
  )
}

export default Casequotewiththumbsec
