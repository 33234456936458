import styled from 'styled-components'
import { xl, lg, md, sm } from 'config/variables'
import { images } from 'config/images'

export const CaseresultwithvideosecStyles = styled.div`
margin-bottom:100px;
${xl(`
    margin-bottom: 80px; 
`)} 
  ${lg(`
    margin-bottom: 60px; 
`)} 
${md(`
    margin-bottom: 40px; 
`)} 
.container {
  max-width:953px;
}
.scrollFadeAnimationSec  {
  position: relative; 
  margin-bottom: 111px;
  ${xl(`
      margin-bottom: 100px; 
  `)} 
    ${lg(`
      margin-bottom: 100px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 
  .bookRideImgRow {
    padding-bottom: 51px;
    ${md(`
        margin:0 -5px 0;
        padding-bottom:0;    
      `)}
    ${sm(`
        margin-top:30px;
        flex-direction: column;
      `)}
    .bookRideImg {
      width:calc(33.33% + 38px);
      flex-basis:calc(33.33% + 38px);
      ${md(`
        width: 33.33%;
        flex-basis: 33.33%;
        flex-grow: 0;
        flex-shrink: 0;
        padding:5px;
      `)}
      ${sm(`
        width: 100%;
      `)}
      &:nth-child(2) {
        top:77px;
      }
    }
  }
}
.resultwithvideoContent {
  margin-bottom: 111px;
  ${xl(`
      margin-bottom: 100px; 
  `)} 
    ${lg(`
      margin-bottom: 100px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 
  .resultwithvideoRow {
    display: flex;
    justify-content: space-between;
    ${xl(`
        margin:0 -20px;
    `)} 
    ${lg(`
      margin:0;
      flex-direction: column;
    `)}  
     .resultContent {
      flex: 0 1 auto;
      padding-right:44px;
      ${xl(`
          flex: 1 1 auto;
          padding:0 20px;
      `)} 
       ${lg(`
          padding:0px 0 40px;
          flex: 1 1 auto;
      `)}  
      ${md(`
          padding:0px 0 20px;
      `)}   
    }
    .resultVideoContent {
      flex: 0 0 369px;
      ${xl(`
          flex: 0 0 369px;
          padding:0 20px;
      `)} 
      ${lg(`
          flex: 1 1 auto;
          max-width:369px;
          align-self: center;
           width: 100%;
      `)}  
      video {
        width:100%;
        height:auto;
      }
    }
  }
}
.resultScreenListSec {
  background:url(${images.craypayCircleBg}) no-repeat; 
  background-size: 100% 100%;
  background-position: -170px center; 
   .Mobileappscreensec {
     margin:0px;
     .AppScreenMain {
       background:transparent;
       padding:0px;
       .AppScreenRow {
         justify-content:space-between;
         .AppScreenItem {
          flex: 0 1 313px;
          .appScreenMockup {
            padding-bottom:109px;
            &:last-child {
              padding-bottom:0px;
            }
          }
         }
       }
     }
   }
}
`
