import styled from 'styled-components'
import { md, sm } from 'config/variables'
export const ScrollfadeanimationsecStyles = styled.div`
  .bookRideImgRow {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 32px;
    ${md(`
        margin:30px -5px 0;
        padding-bottom:0;    
      `)}
    ${sm(`
        margin-top:30px;
        flex-direction: column;
      `)}
    .bookRideImg {
      width: calc(33.33% + 62px);
      flex-basis: calc(33.33% + 62px);
      flex-grow: 0;
      flex-shrink: 0;
      transform-style: preserve-3d;
      transition: all 0.5s ease-in-out;
      img {
        width: 100%;
      }
      ${md(`
        width: 33.33%;
        flex-basis: 33.33%;
        flex-grow: 0;
        flex-shrink: 0;
        padding:5px;
      `)}
      ${sm(`
        width: 100%;
      `)}
      &:first-child {
      }
      &:nth-child(2) {
        position: absolute;
        left: 0;
        right: 0;
        top: 32px;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        ${md(`
          position: static;
        `)}
      }
      &:last-child {
      }
    }
    &.isHide {
      .bookRideImg {
        opacity: 0;
        will-change: transform, opacity;
        transform: translate3d(70px, 10px, 0px);
        &:first-child {
          transition-delay: 0.6s;
        }
        &:nth-child(2) {
          transition-delay: 0.4s;
        }
        &:last-child {
          transition-delay: 0.2s;
        }
      }
    }
    &.isShow {
      .bookRideImg {
        will-change: transform, opacity;
        transform: translate3d(0px, 0px, 0px);
        opacity: 1;
        &:first-child {
          transition-delay: 0.2s;
        }
        &:nth-child(2) {
          transition-delay: 0.4s;
        }
        &:last-child {
          transition-delay: 0.6s;
        }
      }
    }
  }
`
