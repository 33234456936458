import styled from 'styled-components'
import { images } from 'config/images'
import { xl, lg, md, metaFont, black } from 'config/variables'
export const CasequotewiththumbsecStyles = styled.div`
  position: relative;
  margin-top: 93px;
  margin-bottom: 138px;
  ${xl(`
    margin-top: 80px;
    margin-bottom: 120px;
  `)}
  ${lg(`
    margin-top: 67px; 
    margin-bottom: 90px;
    padding-bottom:40px;
  `)}
   ${md(`
    margin-top: 30px; 
    margin-bottom: 40px;
    padding-bottom:30px;
  `)}

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 790px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    z-index: -1;
  }
  .container {
    position: relative; 
    .quoteContentRow {  display:flex;
      align-items:center; 
      ${lg(`
           flex-direction:column; 
      `)} 
      .quoteThumb {
        flex:0 0 478px;
        margin-bottom: -79px;
        align-self: flex-end;
        ${xl(`
           flex:0 0 230px;
           margin-bottom: -36px;
        `)}
        ${lg(`
           width: 100%;
          flex: 1 1 auto;
          max-width: 300px;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px;
        `)}
      }
      .quoteContentCol { 
        padding:20px 0 20px 62px;
        flex:1 1 auto; 
        ${lg(`
           padding:0;
        `)}
        .quote-content {  
          width: 100%;
          position: relative; 
          .iconquoteRow {
            display:flex;
            align-items:center;
            .iconquote { 
              width: 83px;
              height: 59px; 
              background: url(${images.yellowLgQuote});
              ${md(`
                width: 40px;
                height: 28px;
                background-size:cover;
              `)}
            }
            hr {
              border:none;
              flex:1 1 auto;
              margin-left: 43px;
              border-top: 1px solid #e3e3e3;
              ${lg(`
                margin-left: 20px;
              `)}
            }
          }
          .quote-content-wrapper { 
            padding: 21px 0 0 49px;
            ${md(`
              padding: 34px 0 0 5px;
            `)}
            .quote {
              font-size: 32px;
              line-height: 50px;
              font-family: ${metaFont};
              font-weight: 700;        
              color:${black}
              ${xl(`
                font-size: 28px;
                line-height: 42px;
              `)}
              ${lg(`
                font-size: 28px;
                line-height: 42px;
              `)}
              ${md(`
                font-size: 20px; 
                line-height: 30px;
              `)}
              span {
                color: #1a49ba;
              }
            }
            .user-info {
              margin-top: 31px;
              display: flex;
              align-items: center;
              ${xl(`
                margin-bottom: 25px;
              `)}
              ${md(`
                margin-bottom: 20px;
              `)}
              .user-img {
                width: 50px;
                margin-right: 16px;
              }
              .username {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                ${md(`
                font-size: 17px;
                line-height: 21px;
              `)}
                .designation {
                  font-weight: 400;
                  position: relative;
                  padding-left: 15px;
                  margin-left: 15px;
                  ${md(`
                      display:block;
                      padding-left: 0;
                  margin-left: 0;
                  `)}
                  &:after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 18px;
                    background: #3d3d3d;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    ${md(`
                      display:none;
                    `)}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
