import React from 'react'
import { TwocolcontentStyles } from './Twocolcontent.styles'
import Image from 'components/ImgOptimized'

function Twocolcontent(props) {
  const { twocolcontentProps } = props.twocolcontentdata

  return (
    <TwocolcontentStyles className="twocolcontentSec">
      <div className="container">
        <h2>{twocolcontentProps.heading}</h2>
        <div className="row">
          <div className="col-head">
            <h3>{twocolcontentProps.maintitle}</h3>
          </div>
          <div className="col-content">
            <p>{twocolcontentProps.mainpara}</p>
            {props.colTopDaigram && (
              <figure>
                <Image
                  fluid={props.colTopDaigram}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 511,
                  }}
                />
                <div className="img-caption">
                  {twocolcontentProps.mainImgcaption}
                </div>
              </figure>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-head bottom-col-head">
            <div className="border-heading">
              <h4>{twocolcontentProps.subheading}</h4>
              <p>{twocolcontentProps.subheadingtitle}</p>
            </div>
          </div>
          <div className="col-content">
            <p>{twocolcontentProps.subpara}</p>
            {props.colBottomDaigram && (
              <figure>
                <Image
                  fluid={props.colBottomDaigram}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 511,
                  }}
                />
                <div className="img-caption">
                  {twocolcontentProps.subImgcaption}
                </div>
              </figure>
            )}
          </div>
        </div>
      </div>
    </TwocolcontentStyles>
  )
}

export default Twocolcontent
